import { createSlice, createSelector } from '@reduxjs/toolkit'
import { symbolsApi } from '../api/symbolsApi'

import { RootState } from 'app/AppStore'

import { toast } from 'react-toastify'

import { groupByGroup } from '../helpers/helpers'

type symbolsSliceState =
  | {
      symbols: {
        [key: string]: object
      }
      quotes: {
        [key: string]: object
      }

      markets: string[]
      groups: string[]
      history: object[]
    }
  | {
      symbols: {
        [key: string]: object
      }
      markets: string[]
      groups: string[]
      history: object[]
      quotes: {
        [key: string]: object
      }
    }

const initialState: symbolsSliceState = {
  symbols: {},
  markets: ['forex', 'stocks', 'crypto', 'commodities', 'metals', 'indices'],
  groups: [],
  quotes: {},
  history: [],
}

export const symbolsSlice = createSlice({
  name: 'symbols',
  initialState,
  reducers: {
    clearData: state => {
      state.symbols = {}
    },
    setSymbolQuote: (state, { payload }) => {
      state.quotes = {
        ...state.quotes,
        ...payload,
      }

      // state.symbols[payload.symbol].quote = {
      //   ...state.symbols[payload.symbol].quote,
      //   ...payload.quote,
      //   prevVolume: state.symbols[payload.symbol].quote?.volume,
      // }
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      symbolsApi.endpoints.symbols.matchFulfilled,
      (state, { payload }) => {
        state.groups = groupByGroup(payload.data)
        state.symbols = payload.data
      },
    )

    builder.addMatcher(
      symbolsApi.endpoints.symbolsHistory.matchFulfilled,
      (state, { payload }) => {
        state.history = payload.data
      },
    )
  },
})

export const selectCurrentChart = (state: RootState) => state.session.me

export const selectMarkets = (state: RootState) => state.symbols.markets

export const selectSymbols = (state: RootState) => state.symbols.symbols

export const selectGroups = (state: RootState) => state.symbols.groups

export const selectQuotes = (state: RootState) => state.symbols.quotes

export const selectHistory = (state: RootState) => state.symbols.history

export const selectGroupedSymbols = createSelector(
  [selectGroups],
  groups => groups,
)

export const selectSymbolHistory = symbol =>
  createSelector([selectHistory], history => history[symbol])

export const selectSymbolByName = (name: keyof symbolsSliceState['symbols']) =>
  createSelector([selectSymbols], symbols => symbols[name])

export const selectQuoteByName = (name: keyof symbolsSliceState['symbols']) =>
  createSelector([selectQuotes, selectSymbols], (symbols, symbolQuote) => {
    if (symbols[name]) return symbols[name]

    const quoteInit = symbolQuote[name]
    if (quoteInit) {
      return {
        volume: quoteInit?.quote?.ohlc_week?.open,
        p: quoteInit?.quote?.ohlc_week?.open,
        symbol: name,
        bidPrice: quoteInit?.quote?.ohlc_week?.open,
        askPrice: quoteInit?.quote?.ohlc_week?.open,
        type: quoteInit.type,
      }
    }
  })

export const { clearData, setSymbolQuote } = symbolsSlice.actions
