import { baseApi } from 'shared/api'

export const symbolsApi = baseApi.injectEndpoints({
  endpoints: build => ({
    symbols: build.query<any, void>({
      query: accGroupId => ({
        url: `symbols/cfd/list?per_page=400&acc_group_id=${accGroupId}`,
      }),

      // transformResponse: (response) => mapUser(response.data),
    }),

    symbolsHistory: build.query<any, void>({
      query: params => ({
        url: `/cfd/history`,
        params,
      }),

      // transformResponse: (response) => mapUser(response.data),
    }),
  }),
})

export const { useSymbolsQuery, useSymbolsHistoryQuery } = symbolsApi
