import { ORDER_VOLUME_TYPE } from 'entities/orders/lib/constants'

// Основная функция расчета маржи
export const calculateMargin = (orders, leverage, symbols) => {
  let totalOrderValue = 0

  orders.forEach(order => {
    const { volume, open_price, volume_type, symbol } = order

    // Получаем тип символа из переданного списка символов
    const orderSymbolType = symbols?.[symbol]?.type

    // Получаем параметры плеча и размер лота для каждого типа актива
    const leverageValue = leverage?.[orderSymbolType]?.leverage_value || 1
    const lotSize = leverage?.[orderSymbolType]?.lot_size || 100000

    let orderVolume = volume

    // Объект для вычисления маржи для каждого типа актива
    const marginCalculators = {
      forex: () => {
        if (
          volume_type === ORDER_VOLUME_TYPE.LOTS ||
          volume_type === ORDER_VOLUME_TYPE.UNIT
        ) {
          const volumeInUnits = volume * lotSize
          return (volumeInUnits / leverageValue) * open_price
        }
        if (volume_type === ORDER_VOLUME_TYPE.CURRENCY) {
          return volume / leverageValue
        }
        throw new Error('Invalid volume type for forex')
      },
      crypto: () => {
        if (
          volume_type === ORDER_VOLUME_TYPE.LOTS ||
          volume_type === ORDER_VOLUME_TYPE.UNIT
        ) {
          return (volume * open_price) / leverageValue
        }
        if (volume_type === ORDER_VOLUME_TYPE.CURRENCY) {
          return volume / leverageValue
        }
        throw new Error('Invalid volume type for crypto')
      },
      stocks: () => {
        if (
          volume_type === ORDER_VOLUME_TYPE.LOTS ||
          volume_type === ORDER_VOLUME_TYPE.UNIT
        ) {
          return (volume * open_price) / leverageValue
        }
        if (volume_type === ORDER_VOLUME_TYPE.CURRENCY) {
          return volume / leverageValue
        }
        throw new Error('Invalid volume type for stocks')
      },
      commodities: () => {
        if (
          volume_type === ORDER_VOLUME_TYPE.LOTS ||
          volume_type === ORDER_VOLUME_TYPE.UNIT
        ) {
          const volumeInUnits = volume * lotSize
          return (volumeInUnits / leverageValue) * open_price
        }
        if (volume_type === ORDER_VOLUME_TYPE.CURRENCY) {
          return volume / leverageValue
        }
        throw new Error('Invalid volume type for commodities')
      },
      metals: () => {
        if (
          volume_type === ORDER_VOLUME_TYPE.LOTS ||
          volume_type === ORDER_VOLUME_TYPE.UNIT
        ) {
          const volumeInUnits = volume * lotSize
          return (volumeInUnits / leverageValue) * open_price
        }
        if (volume_type === ORDER_VOLUME_TYPE.CURRENCY) {
          return volume / leverageValue
        }
        throw new Error('Invalid volume type for metals')
      },
      indices: () => {
        if (
          volume_type === ORDER_VOLUME_TYPE.LOTS ||
          volume_type === ORDER_VOLUME_TYPE.UNIT
        ) {
          const volumeInUnits = volume * lotSize
          return (volumeInUnits / leverageValue) * open_price
        }
        if (volume_type === ORDER_VOLUME_TYPE.CURRENCY) {
          return volume / leverageValue
        }
        throw new Error('Invalid volume type for indices')
      },
      default: () => {
        return 0
      },
    }

    // Вызываем соответствующий калькулятор маржи для типа актива
    const margin = (
      marginCalculators[orderSymbolType] || marginCalculators.default
    )()

    // Суммируем общий объем маржи
    totalOrderValue += margin
  })

  return totalOrderValue
}

//old version

//import { ORDER_VOLUME_TYPE } from 'entities/orders/lib/constants'

// export const calculateMargin = (orders, leverage, symbols) => {
//   let totalOrderValue = 0

//   orders.forEach(order => {
//     const { volume, open_price, volume_type, symbol } = order

//     const orderSymbolType = symbols?.[symbol]?.type

//     const leverageValue = leverage?.[orderSymbolType]?.leverage_value || 1
//     const lotSize = leverage?.[orderSymbolType]?.lot_size || 100000
//     let orderVolume = volume

//     if (volume_type === ORDER_VOLUME_TYPE.CURRENCY) {
//       orderVolume = volume / open_price
//     }
//     if (
//       volume_type === ORDER_VOLUME_TYPE.UNIT ||
//       volume_type === ORDER_VOLUME_TYPE.LOTS
//     ) {
//       const volumeInUnits = volume * lotSize
//       orderVolume = (volumeInUnits / leverageValue) * open_price
//     }

//     totalOrderValue += orderVolume
//   })

//   return totalOrderValue
// }
