import {
  createListenerMiddleware,
  isAnyOf,
  type TypedStartListening,
} from '@reduxjs/toolkit'

import { sessionApi } from './sessionApi'

import { currencyApi } from 'entities/currency/api/currencyApi'
import { Convert } from 'easy-currencies'
import { RootState, AppDispatch } from 'app/AppStore'
import { setRates } from 'entities/currency/model/slice'

import { accountsApi } from 'entities/accounts/api/accountsApi'

import { supportApi } from 'entities/support/api/supportApi'

import { setCurrentAccount } from 'entities/accounts/model/slice'

import { symbolsApi } from 'entities/symbols/api/symbolsApi'

import { wsApi } from 'services/SocketService/SocketService'

import { logoutThunk } from 'features/auth/logout/model/logout'

export const loginSuccessListener = createListenerMiddleware()

export type TypedListening = TypedStartListening<RootState, AppDispatch>

export const startListenerOnSuccessLogin =
  loginSuccessListener.startListening as TypedListening

startListenerOnSuccessLogin({
  matcher: isAnyOf(
    sessionApi.endpoints.login.matchFulfilled,
    sessionApi.endpoints.auto_login.matchFulfilled,
    sessionApi.endpoints.me.matchFulfilled,
  ),
  effect: async (action, api) => {
    await api.dispatch(sessionApi.endpoints.settings.initiate())
    await api.dispatch(sessionApi.endpoints.me.initiate())
    await api.dispatch(accountsApi.endpoints.accounts.initiate())
    await api.dispatch(currencyApi.endpoints.currency.initiate())

    // const convert = await Convert().from('USD').fetch()
    // if (convert?.rates) {
    //   //window.rates = convert?.rates

    //   api.dispatch(setRates(convert?.rates))
    // }

    const res = await api.dispatch(
      currencyApi.endpoints.currencyRate.initiate(),
    )
    if (res) {
      api.dispatch(setRates(res?.data?.data))
    }

    await api.dispatch(wsApi.endpoints.subscribeToEvents.initiate())

    const state = api.getState()

    if (state?.session?.me?.chat_id) {
      api.dispatch(
        supportApi.endpoints.messages.initiate(state.session.me.chat_id),
      )
    }

    if (!!state?.accounts.list.length) {
      api.dispatch(setCurrentAccount(state?.accounts.list[0]))
      await api.dispatch(
        symbolsApi.endpoints.symbols.initiate(
          state?.accounts.list[0]?.group?.id,
        ),
      )
    } else {
      api.dispatch(logoutThunk())
    }
  },
})
