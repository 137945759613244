export const priceToView = (
  price: string | number,
  currency = 'USD',
  rate = 1,
  isAfterDotShow = false,
  maximumFractionDigits = 2,
): string => {
  let newPrice = '' + price * rate
  newPrice = newPrice.replace(/[^-\d.]/g, '')

  if (newPrice !== '' && newPrice !== '-')
    newPrice = (+newPrice).toLocaleString('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits,
    })

  return /\.00$/.test(newPrice) && !isAfterDotShow
    ? newPrice.replace(/,/g, ' ').replace(/\./g, '.')
    : newPrice
}

export const priceToViewFull = (
  price: string | number,
  currency = 'USD',
  rate = 1,
  isAfterDotShow = false,
  maximumFractionDigits = 7,
): string => {
  let newPrice = '' + price * rate

  newPrice = newPrice.replace(/[^-\d.]/g, '')

  if (newPrice !== '' && newPrice !== '-')
    newPrice = (+newPrice).toLocaleString('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits,
    })

  return /\.00$/.test(newPrice) && !isAfterDotShow
    ? newPrice.replace(/,/g, ' ').replace(/\./g, '.')
    : newPrice
}
