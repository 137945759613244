import React, { useState } from 'react'
import cn from 'classnames'
import styles from './PaymentInfo.module.sass'

import { TextInput, Dropdown } from 'shared/ui'
import { useAppSelector } from 'app/AppStore'
import { selectCerrencyList } from 'entities/currency/model/slice'
import * as Yup from 'yup'

const cards = [
  {
    image: './images/content/visa.svg',
    alt: 'Visa',
  },
  {
    image: './images/content/master-card.svg',
    alt: 'Master Card',
  },
]

const validationSchema = Yup.object().shape({
  card_number: Yup.string()
    .matches(/^\d{4} \d{4} \d{4} \d{4}$/, 'Card number must be 16 digits')
    .required('Card number is required'),
  card_holder: Yup.string().required('Card holder name is required'),
  expiration: Yup.string()
    .matches(/^\d{2} \/ \d{2}$/, 'Expiration date must be MM / YY')
    .required('Expiration date is required'),
  cvc: Yup.string()
    .matches(/^\d{3}$/, 'CVC must be 3 digits')
    .required('CVC is required'),
  amount: Yup.number()
    .required('Amount is required')
    .positive('Amount must be positive'),
  currency: Yup.string().required('Currency is required'),
})

export const PaymentCard = ({ onSave, onChange, state }) => {
  const currency = useAppSelector(selectCerrencyList)
  const [errors, setErrors] = useState({})

  const clearError = fieldName => {
    setErrors(prevErrors => ({ ...prevErrors, [fieldName]: undefined }))
  }

  const handleCardNumberChange = e => {
    const { value } = e.target
    const formattedValue = value
      .replace(/\s?/g, '')
      .replace(/(\d{4})/g, '$1 ')
      .trim()

    if (formattedValue.length <= 19) {
      clearError('card_number')
      onChange({
        target: {
          name: 'card_number',
          value: formattedValue,
        },
      })
    }
  }

  const handleSave = async () => {
    try {
      await validationSchema.validate(state, { abortEarly: false })
      setErrors({})
      onSave()
    } catch (err) {
      const validationErrors = {}
      err.inner.forEach(error => {
        validationErrors[error.path] = error.message
      })
      setErrors(validationErrors)
    }
  }

  const handleExpirationChange = e => {
    const { value } = e.target
    const formattedValue = value
      .replace(/\s?/g, '')
      .replace(/(\d{2})(\d{2})/, '$1 / $2')
      .trim()
    if (formattedValue.length <= 7) {
      clearError('expiration')
      onChange({
        target: {
          name: 'expiration',
          value: formattedValue,
        },
      })
    }
  }

  const handleCVCChange = e => {
    const { value } = e.target
    if (value.length <= 3) {
      clearError('cvc')
      onChange({
        target: {
          name: 'cvc',
          value: value,
        },
      })
    }
  }

  return (
    <div className={styles.item}>
      <div className={styles.line}>
        <div className={styles.subtitle}>Credit Card</div>
        <div className={styles.cards}>
          {cards.map((x, index) => (
            <div className={styles.card} key={index}>
              <img src={x.image} alt={x.alt} />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label='Card Number'
          name='card_number'
          type='tel'
          placeholder='XXXX XXXX XXXX XXXX'
          required
          autoComplete='off'
          value={state.card_number}
          onChange={handleCardNumberChange}
          note={errors?.card_number}
        />
        <TextInput
          className={styles.field}
          label='Card Holder'
          name='card_holder'
          type='text'
          placeholder='TRAN MAU TRI TAM'
          required
          value={state.card_holder}
          onChange={onChange}
          note={errors?.card_holder}
        />
        <div style={{ marginBottom: '20px' }} className={styles.row}>
          <TextInput
            className={styles.field}
            label='Expiration Date'
            name='expiration'
            type='tel'
            placeholder='MM / YY'
            required
            value={state.expiration}
            onChange={handleExpirationChange}
            note={errors?.expiration}
          />
          <TextInput
            className={styles.field}
            label='CVC'
            name='cvc'
            type='number'
            placeholder='CVC'
            required
            value={state.cvc}
            onChange={handleCVCChange}
            note={errors?.cvc}
          />
        </div>
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label='Amount'
            name='amount'
            type='number'
            placeholder={state.currency}
            required
            value={state.amount}
            onChange={e => {
              clearError('amount')
              onChange(e)
            }}
            note={errors?.amount}
          />
          <Dropdown
            className={styles.dropdown}
            label='Currency'
            value={state.currency}
            setValue={value => {
              clearError('currency')
              onChange({
                target: {
                  name: 'currency',
                  value,
                },
              })
            }}
            options={currency.map(item => item.title)}
          />
        </div>
      </div>
      <div className={styles.btns}>
        <button
          // disabled={!state?.amount || !!Object.values(errors)?.length}
          className={cn('button', styles.button)}
          onClick={handleSave}
        >
          Continue
        </button>
      </div>
    </div>
  )
}
