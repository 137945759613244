import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Item.module.sass'
import { TransactionData } from 'entities/accounts/api/types'
import {
  transactionType,
  transactionStatusTitle,
  typeTitle,
} from 'entities/accounts/helpers/helpers'
import { priceToView } from 'shared/utils/format/currency'

export const Item = ({
  item,
  handleReverTransaction,
}: {
  item: TransactionData
  handleReverTransaction?: (id: number) => void
}) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} onClick={() => setVisible(!visible)}>
          <div className={styles.col}>
            <div className={styles.currency}>
              <div className={styles.details}>
                <div className={styles.info}>{`${typeTitle[item.type]}`}</div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{`${
              transactionType[item.transaction_mode]
            }`}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              {priceToView(item.amount, item.currency)}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              {transactionStatusTitle[item.status]}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{item.info}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              {item.status === 3 && handleReverTransaction && (
                <button
                  className={cn('button-stroke button-small', styles.button)}
                  onClick={() => handleReverTransaction(item.id)}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
