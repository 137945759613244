import React, { useState } from 'react'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import styles from './Dropdown.module.sass'

import { Icon } from '../Icon/Icon'

export const Dropdown = ({
  className,
  classLabel,
  value,
  setValue,
  options,
  label,
  classDropdownHead,
  classDropdownArrow,
  classDropdownBody,
  classDropdownOption,
  onRemove,
  isSymbol,
}) => {
  const [visible, setVisible] = useState(false)

  const handleClick = (value, idx) => {
    setValue(value, idx)
    setVisible(false)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div
        className={cn(styles.dropdown, className, {
          [styles.active]: visible,
        })}
      >
        <div
          className={cn(classDropdownHead, styles.head)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>
            {isSymbol ? (
              <div className={styles.symbolItem}>
                {' '}
                <div
                  style={{ zoom: '0.25' }}
                  className={`symbol symbol-${value}`}
                ></div>
                <p>{value}</p>
              </div>
            ) : (
              value
            )}
          </div>
          <div className={cn(styles.arrow, classDropdownArrow)}>
            <Icon name='arrow-down' size='24' />
          </div>
        </div>
        <div className={cn(classDropdownBody, styles.body)}>
          {options.map((x, index) => (
            <div
              className={cn(classDropdownOption, styles.option, {
                [styles.selectioned]: x === value,
              })}
              onClick={() => handleClick(x, index)}
              key={index}
            >
              {onRemove ? (
                <p className={styles.removeRow}>
                  {isSymbol ? (
                    <div className={styles.symbolItem}>
                      {' '}
                      <div
                        style={{ zoom: '0.25' }}
                        className={`symbol symbol-${x}`}
                      ></div>
                      <p>{x}</p>
                    </div>
                  ) : (
                    x
                  )}
                  <p
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      onRemove(x)
                    }}
                  >
                    <Icon
                      className={styles.arrowClose}
                      name='close'
                      size='24'
                    />
                  </p>
                </p>
              ) : (
                x
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  )
}
